var images = {
  "p1_1.png": require("./assets/puzzle/p1_1.png"),
  "p1_2.png": require("./assets/puzzle/p1_2.png"),
  "p1_3.png": require("./assets/puzzle/p1_3.png"),
  "p1_4.png": require("./assets/puzzle/p1_4.png"),
  "p1_5.png": require("./assets/puzzle/p1_5.png"),
  "p1_6.png": require("./assets/puzzle/p1_6.png"),
  "p1_7.png": require("./assets/puzzle/p1_7.png"),
  "p1_8.png": require("./assets/puzzle/p1_8.png"),
  "p1_9.png": require("./assets/puzzle/p1_9.png"),
  "botarius.png": require("./assets/intruso/botarius.png"),
  "elaphe.png": require("./assets/intruso/elaphe.png"),
  "emys.png": require("./assets/intruso/emys.png"),
  "hermanni.png": require("./assets/intruso/hermanni.png"),
  "intruso_bg.png": require("./assets/intruso/intruso_bg.png"),
  "lacerta.png": require("./assets/intruso/lacerta.png"),
  "meles.png": require("./assets/intruso/meles.png"),
  "natrix.png": require("./assets/intruso/natrix.png"),
  "sylvia.png": require("./assets/intruso/sylvia.png"),
  "upupa.png": require("./assets/intruso/upupa.png"),
  "apogon.png": require("./assets/posto/apogon.png"),
  "caretta.png": require("./assets/posto/caretta.png"),
  "cephalus.png": require("./assets/posto/cephalus.png"),
  "chromis.png": require("./assets/posto/chromis.png"),
  "diplodus.png": require("./assets/posto/diplodus.png"),
  "hippocampus.png": require("./assets/posto/hippocampus.png"),
  "mullus.png": require("./assets/posto/mullus.png"),
  "muraena.png": require("./assets/posto/muraena.png"),
  "palinurus.png": require("./assets/posto/palinurus.png"),
  "posto_giusto_bg.png": require("./assets/posto/posto_giusto_bg.png")
};
export default images;
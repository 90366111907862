import React from "react";
import ReactDOM from "react-dom";

import SGGame, { TYPE } from "smart_guaceto_web_module/dist";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gameType: null,
      showGameMenu: true,
    };
  }

  onGameCompleted() {
    window.location.reload(false)
  }

  onSelectedGame(type) {
    this.setState({ gameType: type, showGameMenu: false });
  }

  render() {
    return (
      <div className="container">
        {this.state.showGameMenu && (
          <div className="game-type-buttons">
            <button className="gameButton puzzleButton">
              <img
                src={require("./assets/assets/puzzle.jpg")}
                alt="puzzle"
                className="imageButton"
                onClick={() => this.onSelectedGame(TYPE.PUZZLE)}
              />
            </button>

            <button className="gameButton">
              <img
                src={require("./assets/assets/intruso.jpg")}
                alt="intruso"
                className="imageButton"
                onClick={() => this.onSelectedGame(TYPE.TROVA_INTRUSO)}
              />
            </button>
            <button className="gameButton">
              <img
                src={require("./assets/assets/posto_giusto.jpg")}
                alt="posto_giusto"
                className="imageButton"
                onClick={() => this.onSelectedGame(TYPE.POSTO_GIUSTO)}
              />
            </button>
          </div>
        )}
        <div>
          {this.state.gameType != null && (
            <div className="footer">
              <SGGame
                gameType={this.state.gameType}
                onGameCompleted={this.onGameCompleted.bind(this)}
              />
              <div className="changeGameButton" onClick={() => {
                   window.location.reload(false)
                  }}>
                <h4
                  className="changeGameText"
                >
                  CAMBIA GIOCO
                </h4>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

ReactDOM.render(<App />, document.getElementById("root"));

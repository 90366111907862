import games_configuration from "./mock/GameConfigurationMock";
import GAME_TYPE from "./GAME_TYPE";
import images from "./images";
/*   var checkResult = {
  isComplete : false,
  result : false,
  step : 0,
  new_config : {}
} */

var Game = {
  getConfiguration: function getConfiguration(gameType) {
    return games_configuration[gameType][randomGame()];
  },
  validate: function validate(gameConfiguration, boxPosition, item) {
    switch (gameConfiguration.gameType) {
      case GAME_TYPE.PUZZLE:
        return {
          isComplete: gameConfiguration.items.filter(function (x) {
            return !x.isItemPlaced;
          }).length == 1,
          result: item.position.indexOf(boxPosition) != -1
        };

      case GAME_TYPE.POSTO_GIUSTO:
        var res = !item || item.isCorrect;
        gameConfiguration.step = res ? gameConfiguration.step + 1 : gameConfiguration.step;
        return {
          isComplete: gameConfiguration.step == gameConfiguration.countItemsRight,
          result: res
        };

      case GAME_TYPE.TROVA_INTRUSO:
        var res = !item || !item.isCorrect;
        gameConfiguration.step = res ? gameConfiguration.step + 1 : gameConfiguration.step;
        return {
          isComplete: gameConfiguration.step == gameConfiguration.countIntruders,
          result: res
        };

      default:
        return;
    }
  },
  getItemByPosition: function getItemByPosition(gameConfiguration, position) {
    return gameConfiguration.items.find(function (item) {
      return item.position.includes(position);
    });
  },
  getSpriteFromItem: function getSpriteFromItem(item, position) {
    var index = item.position.indexOf(position);

    if (index !== -1 && item.sprite_conf.length > index) {
      return item.sprite_conf[index];
    }

    return;
  },
  getSpriteFromConfiguration: function getSpriteFromConfiguration(gameConfiguration, position) {
    var item = Game.getItemByPosition(gameConfiguration, position);

    if (item) {
      return Game.getSpriteFromItem(item, position);
    }

    return;
  },
  getImage: function getImage(name) {
    return images[name];
  },
  isGameComplete: function isGameComplete(gameConfiguration) {
    return gameConfiguration.items.filter(function (x) {
      return !x.isItemPlaced;
    }).length == 1;
  }
};

var randomGame = function randomGame() {
  var min = 0;
  var max = 0;
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export default Game;
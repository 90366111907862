import _defineProperty from "C:\\Workspaces\\SmartGuaceto\\smart_guaceto_web\\node_modules\\@babel\\runtime/helpers/esm/defineProperty";
import _objectSpread from "C:\\Workspaces\\SmartGuaceto\\smart_guaceto_web\\node_modules\\@babel\\runtime/helpers/esm/objectSpread";

var _games_configuration;

//import { createGameConfiguration, createGameItem } from '../GameConfigurationBuilder';
import GAME_TYPE from '../GAME_TYPE';
import puzzle from '../conf/puzzles.json';
import intruso from '../conf/intruso.json';
import posto from '../conf/posto.json';

var shuffle = function shuffle(items) {
  return items.sort(function () {
    return Math.random() - 0.5;
  });
};

export var games_configuration = (_games_configuration = {}, _defineProperty(_games_configuration, GAME_TYPE.PUZZLE, puzzle.map(function (game) {
  return _objectSpread({}, game, {
    items: shuffle(game.items)
  });
})), _defineProperty(_games_configuration, GAME_TYPE.TROVA_INTRUSO, intruso.map(function (game) {
  return _objectSpread({}, game, {
    items: shuffle(game.items)
  });
})), _defineProperty(_games_configuration, GAME_TYPE.POSTO_GIUSTO, posto.map(function (game) {
  return _objectSpread({}, game, {
    items: shuffle(game.items)
  });
})), _games_configuration);
export default games_configuration;
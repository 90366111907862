var Parameters = {
  HIDE_GAME_GRID: "hideGameGrid",
  SHOW_GAME_BOARD_ITEMS: "showGameBoardItems",
  CLICK_ITEMS_DISABLED: "clickItemsDisabled",
  DRAG_GAME_BOARD_DISABLED: "dragGameBoardDisabled",
  getParameter: function getParameter(configuration, parameter) {
    if (!configuration) {
      return false;
    }

    return configuration[parameter];
  }
};
export default Parameters;